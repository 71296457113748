<template>
  <div class="nav-text-container">
    <span class="nav-bold-text">Направления<br class="new-line"> клиники</span>
    <span class="nav-secondary-text">Мы решаем самые разные, и даже самые сложные задачи</span>
  </div>
</template>

<style scoped lang="scss">
.nav-text-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  @media (max-width: 1023px) {
    gap: 8px;
  }
  & .nav-bold-text {
    font-family: 'Roboto';
    color: #262633;
    font-size: 2.125rem;
    font-style: normal;
    font-weight: 700;
    line-height: 40px;
    letter-spacing: 0.085px;
    @media (max-width: 1023px) {
      font-size: 1.5rem;
      font-style: normal;
      font-weight: 700;
      line-height: 28px;
    }
  }
  & .nav-secondary-text {
    color: #262633;
    font-feature-settings: 'liga' off;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
  }
}
.new-line {
  @media (max-width: 1023px) {
    display: none;
  }
}
</style>
