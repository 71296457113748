<script setup lang="ts">
  const links = [
    { text: 'Неврологи', url: '/vrachi/nevrolog' },
    { text: 'Остеопаты', url: '/vrachi/osteopat' },
    { text: 'Травматологи-ортопеды', url: '/vrachi/travmatolog-ortoped' },
    { text: 'Мануальные терапевты', url: '/vrachi/manualnyj-terapevt' },
    { text: 'УЗИ', url: '/uslugi/uzi' },
    { text: 'ЛФК и реабилитация', url: '/vrachi/vrach-lfk' },
    { text: 'Рефлексотерапия', url: '/uslugi/refleksoterapiya-usluga' },
    { text: 'Физиотерапия', url: '/uslugi/fizioterapiya' },
    { text: 'Психотерапия', url: '/vrachi/psihoterapevt' },
    { text: 'Психология', url: '/uslugi/psihologiya' },
    { text: 'Гинекология', url: '/uslugi/ginekologiya' },
    { text: 'Урология', url: '/uslugi/urologiya' },
    { text: 'Эндокринология и диетология', url: '/vrachi/endokrinolog' },
    { text: 'Гастроэнтерология', url: '/uslugi/gastroenterologiya' },
    { text: 'Медицинский массаж', url: '/uslugi/mediczinskij-massazh' },
    { text: 'Медицинские анализы', url: '/uslugi/analizy' }
  ];

  const { y } = useWindowScroll();

  const displayLinks = ref<'none'|'flex'>('none');

  const toggleButton = async () => {
    const currentY = y.value;
    if (displayLinks.value === 'none') {
      displayLinks.value = 'flex';
      await nextTick();
      y.value = currentY;
      return;
    }
    displayLinks.value = 'none';
  };
</script>

<template>
  <div class="nav-grid">
    <MainPageNavigationText class="grid-text" />
    <MainPageNavigationNeedHelp class="grid-help" />
    <MainPageNavigationLink
      v-for="link in links"
      :key="link.text"
      class="grid-list"
      :url="link.url"
      :text="link.text"
    />
    <button type="button" class="grid-button" @click="toggleButton">
      {{ displayLinks === 'none' ? 'Показать ещё' : 'Скрыть' }}
    </button>
  </div>
</template>

<style scoped lang="scss">
.nav-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(6, 1fr);
  grid-auto-flow: column;
  column-gap: 20px;
  padding-top: 56px;
  padding-bottom: 50px;
  grid-template-areas:
    "text list list"
    "text list list"
    "text list list"
    "text list list"
    "text list list"
    "text list list"
    "text list list"
    "help list list";
  @media (max-width: 1023px) {
    padding-top: 32px;
    padding-bottom: 40px;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(9, 1fr);
    grid-template-areas:
      "text text"
      "list list"
      "list list"
      "list list"
      "list list"
      "list list"
      "list list"
      "list list"
      "list list"
      "help help";
    }
  @media (max-width: 767px) {
    padding-bottom: 30px;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    grid-template-areas:
      "text"
      "list"
      "list"
      "list"
      "list"
      "list"
      "list"
      "list"
      "list"
      "list"
      "list"
      "list"
      "list"
      "list"
      "list"
      "list"
      "list"
      "button"
      "help";
  }
  @media (max-width: 320px) {
    padding-bottom: 20px;
  }
}
.grid-text {
  grid-area: text;
}
.grid-help {
  grid-area: help;
  @media (max-width: 1023px) {
    margin-top: 24px;
  }
  @media (max-width: 767px) {
    margin: 0px;
  }
}
.grid-list a {
  grid-area: list;
}
/* a:nth-child(1 of .grid-list) {
  display: none;
  @media (max-width: 767px) {
    display: flex;
  }
}
a:nth-child(2 of .grid-list) {
  display: none;
  @media (max-width: 1023px) {
    display: flex;
  }
} */
/* range 7..14 */
a:nth-child(n+7 of .grid-list):nth-child(-n+14 of .grid-list) {
  @media (max-width: 767px) {
    display: v-bind(displayLinks);
  }
}
.grid-button {
  grid-area: button;
  display: none;
  @media (max-width: 767px) {
    display: flex;
    justify-content: center;
    margin: 24px 0;
    padding: 14px 16px;
    border-radius: 15px;
    background: #F2F3F6;
    cursor: pointer;
    color: #262633;
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: 125%;
  }
}
</style>
