<script setup lang="ts">
  const { showHelpModal } = storeToRefs(useBookingStore());
</script>

<template>
  <div class="need-help-container">
    <div class="help-img"></div>
    <div class="right">
      <span class="help-bold-text">Нужна помощь в выборе?</span>
      <span class="help-secondary-text" @click="useBookingStore().openHelpModal">Обратиться к специалисту</span>
    </div>
    <PatientHelpFormModal v-if="showHelpModal" />
  </div>
</template>

<style scoped lang="scss">
.need-help-container {
  display: flex;
  align-items: center;
  gap: 1rem;
  & .help-img {
    width: 44px;
    height: 44px;
    border-radius: 44px;
    background: url(~/assets/img/main-page/nav-need-help.webp) lightgray 0px -0.683px / 100% 133.333% no-repeat;
  }
  & .right {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }
  & .help-bold-text {
    color: #262633;
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: 125%;
  }
  & .help-secondary-text {
    color: #2A76E8;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    cursor: pointer;
  }
}
</style>
